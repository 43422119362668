<template>
  <c-home-page style="gap: 0px !important">
    <!-- <c-hero-section></c-hero-section> -->
    <c-top-nav-bar style="position: relative !important"></c-top-nav-bar>
    <div style="width: 100%">
      <!-- <hr class="horizontal-line" /> -->
      <div class="heroSection">
        <vueper-slides
          ref="myVueperSlides"
          fixed-height="400px"
          slide-image-inside
          arrows-outside
          always-refresh-clones
          autoplay
          :arrows="false"
          :pause-on-hover="pauseOnHover"
          @autoplay-pause="internalAutoPlaying = false"
          @autoplay-resume="internalAutoPlaying = true"
        >
          <vueper-slide v-for="(slide, idx) in slides" :key="idx">
            <template #content>
              <!--linear-gradient(to right, #yourColor 50%, transparent), url('your-image-url.jpg')-->
              <!--((blurDirection==='to right') ? slide.color : slide.bottomColor)-->
              <div
                class="vueperslide__content-wrapper slideCardRenderer"
                :style="{
                  'background-image':
                    'linear-gradient(' +
                    blurDirection +
                    ', transparent ' +
                    (blurDirection === 'to bottom' ? '90%' : '55%') +
                    ', ' +
                    slide.color +
                    ' 100%, transparent), url(' +
                    slide.image +
                    ')',
                  'background-color': slide.color,
                }"
              >
                <div class="vueperslide__title" v-html="slide.content"></div>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <!-- <hr class="horizontal-line" /> -->
      <c-about-us></c-about-us>
      <!-- <hr class="horizontal-line" /> -->
      <c-tutor-invite></c-tutor-invite>
      <!-- <hr class="horizontal-line" /> -->
      <!-- <c-upcoming-course-section :isLoadingCoursesListData="isLoadingCoursesListData" :upcomingCoursesListData="upcomingCoursesListData" :key="upcomingCoursesListData"></c-upcoming-course-section> -->
      <!-- <c-how-it-work-section ></c-how-it-work-section> -->
      <!-- <c-meet-team-section :meetTheTeamListData="meetTheTeamListData" :key="meetTheTeamListData"></c-meet-team-section> -->
      <c-footer></c-footer>
    </div>
  </c-home-page>
</template>

<script>
import AppConfig from "../../config.js";
import axios from "axios";
import topNavBar from "../organisms/topNavBar.vue";
import aboutUs from "../organisms/aboutUsSection.vue";
import tutorInvite from "../organisms/tutorInviteSection.vue";
// import hero_section from '../organisms/hero_section.vue'
import { theme } from "../assets/theme.js";
import footerComp from "../organisms/footerComp.vue";
// import meet_team_section from '../organisms/meet_team_section.vue'
// import how_it_work_section from '../organisms/how_it_work_section.vue'
// import upcoming_course_section from '../organisms/upcoming_course_section.vue'
import { homePageDiv } from "../atoms/div_styles";
import { VueperSlides, VueperSlide } from "vueperslides";
export default {
  name: "unsignedHome",
  components: {
    "c-home-page": homePageDiv,
    // 'c-hero-section': hero_section,
    // 'c-how-it-work-section':how_it_work_section,
    "c-footer": footerComp,
    // 'c-upcoming-course-section': upcoming_course_section,
    // 'c-meet-team-section': meet_team_section,
    "c-top-nav-bar": topNavBar,
    "c-about-us": aboutUs,
    "c-tutor-invite": tutorInvite,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      UI_baseURL: AppConfig.UI_Base_URL,
      auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
      colorTheme: theme,
      upcomingCoursesListData: [],
      meetTheTeamListData: [],
      isLoadingCoursesListData: true,
      pauseOnHover: true,
      autoPlaying: true,
      internalAutoPlaying: true,
      blurDirection: "to left",
      slides: [
        {
          color: "#ffffff",
          image: require("../assets/carousel-images/t1/t1-padded-h250-some-color.png"),
          content:
            '<p class="H2_34" style="color: #00CED1;">Right place for STEM teachers. </p>  <p class="H4_22" style="color: #424D4D;"> Inspired teaching; not rote makes a better world.</p> <p class="body_14" style="color: #F22594;"> Great tutors get their students interested in subject, Rest is history. </p>',
        },
        {
          color: "#ffffff",
          image: require("../assets/carousel-images/t2/t2-h250-sym.png"),
          content:
            '<p class="H2_34" style="color: #00CED1;">Your students are your brand!!!</p>  <p class="H4_22" style="color: red;"> Star makers get famous on social media. </p> <p class="body_14" style="color: blue;"> Society loves and respect best teachers. </p>',
        },
        {
          color: "#d8c49c",
          image: require("../assets/carousel-images/t3/t3-h250.png"),
          content:
            '<p class="H2_34" style="color: #424D4D;"> Best results are when students enjoy learning. </p>  <p class="H4_22" style="color: red;"> Remember the time when you studied with focus?</p> <p class="body_14" style="color: blue;"> That knowledge is what makes you excel. </p>',
        },
        {
          color: "#ffffff",
          image: require("../assets/carousel-images/t4/t4-h250.png"),
          content:
            '<p class="H2_34" style="color: #00CED1;">Teaching is hard enough. </p>  <p class="H4_22" style="color: red;"> Let us do the grunt work for you. </p> <p class="body_14" style="color: blue;"> while you focus on the quality of learning. </p>',
        },
        {
          color: "#fbfbfb",
          image: require("../assets/carousel-images/t5/t5-h250-partial-color.png"),
          content:
            '<p class="H2_34" style="color: #424D4D;">All the tools you need. </p>  <p class="H4_22" style="color: #F22594;"> For all stakeholders. </p> <p class="body_14" style="color: blue;"> Intelligent intervention during teaching and learning, timely hints, suggestions, memory aids, reports </p>',
        },
        {
          color: "#ffffff",
          image: require("../assets/carousel-images/t6/t6-h250.png"),
          content:
            '<p class="H2_34" style="color: #00CED1;">Make teaching rewarding. </p>  <p class="H4_22" style="color: #424D4D;"> It\'s most enjoyable with parents involved. </p> <p class="body_14" style="color: #F22594;"> Stay in touch with your pupil & their family. Near or Far! </p>',
        },
        // { image: require('../assets/images/c1-padded-test-250.png'), content: '<p class="H2_34" style="color: black;">Right place for STEM </p>  <p class="H4_22" style="color: red;"> Inspired teaching produces better humans;<br> Rote learning achieves the exact opposite </p> <p class="body_14" style="color: blue;"> Great tutors get their students interested in subject;<br> Rest is history </p>'},
        // { image: require('../assets/images/tutor2_final.webp'), content: '<span style="font-size: 1.2em;color: black">Welcome to rasree, online learning platform</span>'},
        // { image: require('../assets/images/philanthropy2_final.webp'),  title: 'slide-2', content: 'content-2, <span style="font-size: 1.2em;color: red">Teaching</span>' },
        // { image: require('../assets/images/tech4_final.webp'), content: '<span style="font-size: 1.2em;color: black">Lets read together</span>'},
        // { image: require('../assets/images/parent3_final.webp'), content: '<span style="font-size: 1.2em;color: Black">Bright future for your kids</span>'},
        // { image: require('../assets/images/learner4_final.webp'), content: '<span style="font-size: 1.2em;color: Black">The journey of a thousand miles begin with one step</span>'},
        // { image: require('../assets/images/educationalist2_final.webp'), content: '<span style="font-size: 1.2em;color: black">Learning to success</span>'},
        // { image: require('../assets/images/govt1_final.webp'), content: '<span style="font-size: 1.2em;color: black">Teaching</span>'}
      ],
    };
  },
  async beforeMount() {
    // this.$root.$children[0].startLoading()
    // api call for get-courses
    await this.getUnsignedHomeSupportFn();
  },
  mounted() {
    this.updateContainerWidth();
    window.addEventListener("resize", this.updateContainerWidth); // Listen for window resize events
    // this.$root.$children[0].stopLoading()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateContainerWidth); // Clean up event listener
  },
  methods: {
    updateContainerWidth() {
      let containerWidth = window.innerWidth;
      if (containerWidth < 560) {
        this.blurDirection = "to bottom";
      } else {
        this.blurDirection = "to left";
      }
    },
    async getUnsignedHomeSupportFn() {
      //api call for get UnsignedHomePageData
      try {
        this.isLoadingCoursesListData = true;
        let unsignedHomePageResp = await axios.get(
          this.auth_api_Base_URL + "/v1_0/unsignedHomePageData"
        );
        console.log("resp from unsignedHomePage Data", unsignedHomePageResp);
        this.upcomingCoursesListData = unsignedHomePageResp.data.courses;
        this.meetTheTeamListData = unsignedHomePageResp.data.socialProofs;
        console.log(
          "unsignedHomePageResp",
          this.upcomingCoursesListData,
          typeof this.upcomingCoursesListData
        );
      } catch (error) {
        console.log(
          "error in resp of get unsignedHomePage Data is:",
          error.response
        );
      }
      this.isLoadingCoursesListData = false;
    },
  },
};
</script>
<style>
.horizontal-line {
  height: 10px;
  width: 100%;
  border: none;
  background-color: #424d4d;
}
.vueperslides__arrow {
  color: white;
}

/* .vueperslide__content:nth-child(1),
.vueperslide__content:nth-child(2),
.vueperslide__content:nth-child(3),
.vueperslide__content:nth-child(4),
.vueperslide__content:nth-child(5){
    width: 100% !important;
    text-align: left !important;
    font-weight: 700 !important;
} */

/* .vueperslide__content:nth-child(6){
    width: 100% !important;
    text-align: right !important;
    font-weight: 700 !important;
} */

.vueperslide__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100% !important;
  text-align: left !important;
  font-weight: 700 !important;
}

.vueperslide__content-wrapper {
  font-size: 1.5rem; /*1rem=16px*/
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 16px;
  word-break: keep-all;
}

.vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
}
.vueperslides__bullet--active .default {
  background-color: #42b983;
}
.vueperslide {
  white-space: normal;
  background-size: cover;
  flex-shrink: 0;
  display: block;
  width: 100%;
  position: relative;
}
.vueperslide__image {
  background-position: right;
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto !important;
  background-size: cover;
  /* border-radius: 8px; */
}
.vueperslide__title {
  font-size: 4em;
  text-align: left;
  width: 50% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*background: linear-gradient(to right, rgb(255 255 255 / 30%) 50%,  rgba(0, 0, 0, 0) 100% );*/
}
.heroSection {
  width: 100%;
  height: 100%;
}
.slideCardRenderer {
  width: 100% !important;
  height: 400px !important; /* Set the height of the div */
  background-repeat: no-repeat !important;
  background-position: right center !important; /* Align the image to the right */
  background-size: 1000px 400px !important;
}
/* 
@media (max-width: 480px) {
    .vueperslide__image {
        background-position: right !important;
    }

    .vueperslide__content {
        width: 50% !important;
        text-align: left !important;
        font-weight: 700 !important;
        font-size: 14px;
    }
} */

@media (min-width: 561px) and (max-width: 920px) {
  .vueperslides {
    height: 400px !important;
  }
  .H2_34 {
    font-size: 32px !important;
  }
  .H4_22 {
    font-size: 26px !important;
  }
  .body_14 {
    font-size: 20px !important;
  }
}

@media (max-width: 560px) {
  .slideCardRenderer {
    width: 100% !important;
    height: auto !important; /* Set the height of the div */
    background-repeat: no-repeat !important;
    background-size: 625px 250px !important;
    display: flex;
    justify-content: flex-end !important;
    background-position: right top !important;
    padding-bottom: 100px;
  }
  .vueperslide__title {
    text-align: center !important;
    width: 100% !important;
    justify-content: flex-end;
  }
  .vueperslides {
    height: 550px !important;
  }
  .H2_34 {
    font-size: 24px !important;
  }
  .H4_22 {
    font-size: 18px !important;
  }
  .body_14 {
    font-size: 14px !important;
  }
}

.H2_34 {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 43px;
  line-height: 140%;
}

.H4_22 {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 140%;
}

.body_14 {
  font-family: Lexend;
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 140%;
}
</style>
