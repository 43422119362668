<template>
  <v-app >
    <div v-if="isLoading" :key="reRenderLoading" class="centered-div">
      <div v-html="loadingSvgSrc" ></div>
    </div>
    <c-top-nav-bar v-if="(showTopNavBar)" :isAuth="isAuth" :roleOfUser="roleOfUser"></c-top-nav-bar>
    <router-view v-if="showFloatingNavBar" :isAuth="isAuth"  :roleOfUser="roleOfUser" style="margin-top: 81px;" />
    <router-view v-if="!showFloatingNavBar" :isAuth="isAuth"  :roleOfUser="roleOfUser" />
  </v-app>
</template>
  
<script>
import topNavBarVue from './organisms/topNavBar.vue';
import axios from 'axios';
import AppConfig from '../config';
export default {
  name: 'App',
  components: {
    'c-top-nav-bar': topNavBarVue
  },
  data(){
    return{
      isAuth : false,
      roleOfUser : "",
      //UI_baseURL: AppConfig.UI_Base_URL,
      auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
      showTopNavBar: false,
      showFloatingNavBar: true,
      jitsiURL: AppConfig.Jitsi_Script_URL,
      loadingSvgSrc: '',
      isLoading: false,
      reRenderLoading: false,
      loadingIntervalId: null,
    }
  },
  async created(){
    let loadingSvgResp = await fetch(require("@/assets/images/animated_logo_100_100.svg"));
    this.loadingSvgSrc = await loadingSvgResp.text();
    this.loadingIntervalId = setInterval(() => {
      this.reRenderLoading = !this.reRenderLoading
    }, 7000);
    // listen for events from routes
    // this.$root.$on("loading-start", this.startLoading());
    // this.$root.$on("loading-stop", this.stopLoading());
    // this.$root.$emit("loading-start");
    // this.$root.$emit("loading-stop");
    // var jitsi_script = document.createElement('script');
    // jitsi_script.setAttribute('src',`https://${this.jitsiURL}/external_api.js`);
    // document.head.appendChild(jitsi_script);
    const script = document.createElement('script');
    script.src = `https://${this.jitsiURL}/external_api.js`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    this.isAuth = this.$keycloak.authenticated
    await this.checkUserAuthFn()
    let routePathName = window.location.pathname
    // let liveSessionPathName = '/learn/session/'
    let liveSessionV3PathName = '/learn/live/'
    if(routePathName.includes(liveSessionV3PathName) || routePathName.includes("/talk/") || ((routePathName === '/') && !(this.isAuth))){
      this.showTopNavBar = false
    }
    else{
      this.showTopNavBar = true
    }
    this.HandleFloatingTopNavBar()
    await this.primaryRoleOfUser()
  },
  beforeDestroy() {
    clearInterval(this.loadingIntervalId)
  },
  methods :{
    startLoading(){
      console.log("start loading called =====================", this.isLoading);
      
      this.isLoading = true
      console.log("start loading called =====================", this.isLoading);
    },
    stopLoading(){
      console.log("stop loading called =====================", this.isLoading);
      this.isLoading = false
      console.log("stop loading called =====================", this.isLoading);
    },
    HandleFloatingTopNavBar(){
      let routePathName = window.location.pathname
      if((routePathName === '/') && !(this.isAuth)){
        this.showFloatingNavBar = false;
      }
      else{
        this.showFloatingNavBar = true
      }
    },
    /**
     * 
     * @deprecated This function is deprecated and it can be removed.
     * We are not using this API calls any more
     */
    async primaryRoleOfUser(){
      //get role of user from the API
      try{
          let allRolesResp = await axios.get(this.auth_api_Base_URL + "/v1_0/me/primary_role")
          console.log("get primary role", allRolesResp)
          this.roleOfUser = allRolesResp.data.primary_role
          console.log("-------user role --------",this.roleOfUser);
      }catch(error){
          console.log("error in resp pf primary role:", error.response)
      }
    },
    async checkUserAuthFn(){
      //check if user is authenticated or not and also check teh crole of user
      //use this info to hide or show some section of UI
      try{
        let resp = await axios.get(this.auth_api_Base_URL + "/v1_0/me")
        console.log("get user resp", resp)
        if(resp.status === 200){
          this.isAuth = true
          //await this.primaryRoleOfUser()
        }
        else{
            this.isAuth = false
        }
        console.log("-------userAuthValue-----", this.isAuth);
      }catch(error){
        console.log("error in resp of getting user info:", error.response)
      }
    }
  }
}
</script>

<style>
@import './main.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #F8FFFF;
}
.centered-div {
  display: flex;
  justify-content: center; /* Align horizontally */
  align-items: center;     /* Align vertically */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;            /* Full width */
  height: 100vh;           /* Full height */
  background-color: rgba(0, 0, 0, 0.61);
}

</style>
