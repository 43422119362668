<template>
    <c-footerSectionDiv>
        <!-- <c-footerLinkGroup-Div >
            <c-footerTitleDiv textalign="left" padding="0px 16px" display="flex">
                <c-H3-27 :color="colorTheme['Secondary-Gray0700']">Courses</c-H3-27>
            </c-footerTitleDiv>
            <div style="display: flex;flex-direction: column;">
                <c-footerFrameSectionDiv v-for="(courseTitle, index) in inputCoursesList" :key="index" >
                    <c-row textalign="left">
                        <c-hypherlink_secondary height="36px" :id="'inputViewCourse_' + (courseTitle.name)" postpendIcon="chevron_right"><span style="text-decoration-line:underline"  @click="gotoBrowseSearchPageFn(courseTitle.name)">{{ courseTitle["name"] }}</span> </c-hypherlink_secondary>
                    </c-row>
                </c-footerFrameSectionDiv>
            </div>
        </c-footerLinkGroup-Div > -->
        <c-footerLinkGroup-Div :order="1" >
            <c-footerTitleDiv textalign="left" padding="0px 16px" display="flex">
                <c-H3-27 :color="colorTheme['Secondary-Gray0700']">Abhyas </c-H3-27>
            </c-footerTitleDiv>
            <div style="display: flex;flex-direction: column;">
                <c-footerFrameSectionDiv v-for="(abhyasSection, index) in inputAbhyasSectionlist" :key="index">
                    <c-row textalign="left">
                        <c-hypherlink_secondary height="36px" :id="'inputViewAbhyasSection_' + abhyasSection.name" postpendIcon="chevron_right"><span style="text-decoration-line:underline" @click="gotoAbhyasFormSectionsFn(abhyasSection.path)">{{ abhyasSection.name }}</span> </c-hypherlink_secondary>
                    </c-row>
                </c-footerFrameSectionDiv>
            </div>
        </c-footerLinkGroup-Div>
        <c-footerLinkGroup-Div  :order="2" >
            <c-footerTitleDiv textalign="left" padding="0px 16px" display="flex">
                <c-H3-27 :color="colorTheme['Secondary-Gray0700']">Terms </c-H3-27>
            </c-footerTitleDiv>
            <div style="display: flex;flex-direction: column;">
                <c-footerFrameSectionDiv v-for="(termsSection, index) in inputTermsSectionList" :key="index">
                    <c-row textalign="left">
                        <c-hypherlink_secondary height="36px"  :id="'inputViewTermsSection_' + termsSection.name" postpendIcon="chevron_right"><span style="text-decoration-line:underline" @click="gotoPrivacyPolicyPageFn(termsSection.path)">{{ termsSection.name }}</span> </c-hypherlink_secondary>
                    </c-row>
                </c-footerFrameSectionDiv>
            </div>
        </c-footerLinkGroup-Div>
    </c-footerSectionDiv>
</template>
<script>
import {footerSectionDiv, row, footerLinkGroupDiv, footerTitleDiv,footerFrameSectionDiv} from '../atoms/div_styles'
import {theme} from '../assets/theme.js'
import {H3_27} from '../atoms/text_styles'
import hypherlink_secondary from '../molecules/hypherlink_secondary.vue'
import axios from 'axios';
import AppConfig from '../../config.js';

export default {
    name:'footerComp',
    components:{
        'c-footerSectionDiv': footerSectionDiv,
        'c-row': row,
        //'c-col': col,
        'c-hypherlink_secondary':hypherlink_secondary,
        'c-H3-27': H3_27,
        'c-footerLinkGroup-Div':footerLinkGroupDiv,
        'c-footerTitleDiv':footerTitleDiv,
        'c-footerFrameSectionDiv':footerFrameSectionDiv
    },
    data(){
        return{
            UI_baseURL: AppConfig.UI_Base_URL,
            auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
            colorTheme:theme,
            inputCourse:"",
            inputCoursesList:[],
            inputAbhyasSectionlist:[
                {
                    name : 'What we offer',
                    path : AppConfig.UI_Base_URL + '/what-we-offer'
                },
                {
                    name : 'FAQ',
                    path : AppConfig.UI_Base_URL + '/FAQ'
                },
                {
                    name : 'Contact us',
                    path : AppConfig.UI_Base_URL + '/contact-us'
                }
            ],
            inputTermsSectionList:[
                {
                    name : 'Privacy policy',
                    path : AppConfig.UI_Base_URL + '/privacy-policy'
                },
                {
                    name : 'Terms of service',
                    path : AppConfig.UI_Base_URL + '/terms-service'
                }
            ]
        }
    },
    async beforeMount() {
        await this.getSubjectsList()
    },
    methods: {
        gotoPrivacyPolicyPageFn(path){
             //redirect to privacy policy page
            let privacyPolicyPageUrl = path // this.UI_baseURL + '/privacy-policy'
            console.log("privacyPolicyPageUrl ", privacyPolicyPageUrl)
            window.location.href = privacyPolicyPageUrl
        },
        gotoAbhyasFormSectionsFn(path){
            console.log("path", path)
            let abhyasFormSectionURL = path
            console.log("abhyasFormSection URL ", abhyasFormSectionURL)
            window.location.href = abhyasFormSectionURL
        },
        gotoBrowseSearchPageFn(inputCourse){
            this.inputCourse= inputCourse
            console.log("inputCourse",this.inputCourse);
            let maintenancePageURL = this.UI_baseURL + '/maintenance'
            console.log("maintenance page url", maintenancePageURL)
            window.location.href = maintenancePageURL
        },
        async getSubjectsList(){
            try{
                let getSubjectListResp = await axios.get(this.auth_api_Base_URL + "/v1_0/subjectsList")
                console.log("resp of getSubjectsList", getSubjectListResp);
                this.inputCoursesList = getSubjectListResp.data.subjects
            }catch(error){
                console.log("error in response of get subject list", error);
            }
        },
    },
}
</script>