<template>
    <c-snack-bar v-show="visible" class="snackbar" :class="{ 'snackbar-active': visible }">
        <c-body-UX-14 style="color: #fff;">
        {{ message }}
        </c-body-UX-14>
        <c-style-icon styledIcon="close" style="color:white"
        @click.native="closeSnackbar"
        ></c-style-icon>
    </c-snack-bar>
</template>
  
<script>
import { snackbarDiv } from '../atoms/div_styles'
import {body_UX_14} from '../atoms/text_styles'
import style_icons from '../molecules/style_icons.vue';
  export default {
    name: 'snack-bar',
    data(){
        return {
            visible: false,
            message: '',
            timeout: null
        }
    },
    props:{
      callByRender:{ // call the show function when rendered or call when needed
        type: Boolean,
        default: false
      },
      renderMessage:{
        type: String
      },
      useDuration:{
        type: Number
      }
    },
    components:{
        'c-snack-bar': snackbarDiv,
        'c-body-UX-14': body_UX_14,
        'c-style-icon': style_icons,
    },
    beforeMount() {
      if(this.callByRender){
        this.show(this.renderMessage, this.useDuration)
      }
    },
    methods: {
        show(message, duration = 5000) {
            this.message = message;
            this.visible = true;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.visible = false;
            }, duration);
        },
        closeSnackbar(){
          this.visible = false
        }
    }
  }
</script>
<style scoped>
.snackbar {
  display: flex;
  justify-content: space-between;
  visibility: hidden;
  min-width: 250px;
  margin-left: 0px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 10;
  left: 20%;
  bottom: 50px;
  font-size: 17px;
  transition: visibility 0.5s, opacity 0.5s linear;
}
.snackbar-active {
  visibility: visible;
  opacity: 1;
}
</style>