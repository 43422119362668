<template>
	<div v-if="isVisible" class="form-container" @click.self="closeBetaInviteForm">
    <div class="form-content">
      <!-- Your form fields go here -->
      <c-H2-34>Get beta invite</c-H2-34>
      <form @submit.prevent="submitForm">
        <c-basic-info-form-input>
            <c-textbox-label-field>
                <c-body-ux-14> Name: </c-body-ux-14>
            </c-textbox-label-field>
            <c-form-input-field id="inputUserName" @input="validateName" :class="{'is-invalid': errors.name}"
            maxlength="100" placeholder="Enter name here.." v-model="form.name" required></c-form-input-field>
            <span v-if="errors.name" class="error">{{ errors.name }}</span>
        </c-basic-info-form-input>
        <c-basic-info-form-input>
            <c-textbox-label-field>
                <c-body-ux-14> Phone number: </c-body-ux-14>
            </c-textbox-label-field>
            <c-form-input-field type="text" id="inputPhoneNumber" @input="validatePhone" :class="{'is-invalid': errors.phone_number}" 
            maxlength="15" placeholder="Enter phone number here.." v-model="form.phone_number" required></c-form-input-field>
            <span v-if="errors.phone_number" class="error">{{ errors.phone_number }}</span>
        </c-basic-info-form-input>
        <c-basic-info-form-input>
            <c-textbox-label-field>
                <c-body-ux-14> Email: </c-body-ux-14>
            </c-textbox-label-field>
            <c-form-input-field id="inputUserEmail" @input="validateEmail" :class="{'is-invalid': errors.email}"
            placeholder="Enter email here.." maxlength="100" v-model="form.email" required></c-form-input-field>
            <span v-if="errors.email" class="error">{{ errors.email }}</span>
        </c-basic-info-form-input>
        <c-basic-info-form-input>
            <c-textbox-label-field>
                <c-body-ux-14> Location: </c-body-ux-14>
            </c-textbox-label-field>
            <c-form-input-field id="inputUserLocation" @input="validateLocation"  :class="{'is-invalid': errors.location}" 
            maxlength="100" placeholder="Enter location here.." v-model="form.location" required></c-form-input-field>
            <span v-if="errors.location" class="error">{{ errors.location }}</span>
        </c-basic-info-form-input>
        <br>
        <div class="action-buttons">
            <c-button-secondary id="BetaInviteSubmitBtn" type="submit">Submit</c-button-secondary>
            <c-button-secondary id="ClearBtn" type="button" @click.native="ClearFields()">Cancel</c-button-secondary>
        </div>
      </form>
      <Snackbar ref="snackbar" />
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import AppConfig from '../../config';
import button_secondary from '../molecules/button_secondary.vue';
import Snackbar from '../molecules/snackbar.vue';
import { body_UX_14, H2_34} from '../atoms/text_styles'
import {basicInfoFormInputDiv, textBoxLabelField, formInputField} from '../atoms/create_course_div_style'
export default{
    name:"betaInviteForm",
    components:{
        'c-basic-info-form-input': basicInfoFormInputDiv,
        'c-textbox-label-field': textBoxLabelField,
        'c-form-input-field': formInputField,
        'c-body-ux-14': body_UX_14,
        'c-H2-34': H2_34,
        'c-button-secondary': button_secondary,
        Snackbar
    },
    data(){
        return{
            UI_baseURL: AppConfig.UI_Base_URL,
            auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
            isVisible: false,
            form: {
                name: '',
                phone_number: '',
                email: '',
                location: ''
            },
            errors: {
                name: null,
                email: null,
                phone_number: null,
                location: null
            }
        }
    },
    methods: {
        /**
         * This function opens the beta invite form
         * @openBetaInviteForm
         */
        openBetaInviteForm(){
            this.isVisible = true
        },
        /** This function close the beta invte form
         * @closeBetaInviteForm
         */
        closeBetaInviteForm() {
            this.isVisible = false
        },
        /**
         * This function collects beta info in json and passes into request call of API
         * And form includes name, phone_number, email and location
         * @submitForm 
         */
        async submitForm(){
            this.validateName();
            this.validateEmail();
            this.validatePhone();
            this.validateLocation();
            console.log("form info", this.form);
            if (!this.errors.name && !this.errors.email && !this.errors.phone && !this.errors.location) {
                try {
                    const response = await axios.post(`${this.auth_api_Base_URL}/v3_1/store-beta-info`, this.form);
                    console.log("response", response);
                    this.$refs.snackbar.show('Registration details saved successfully', 6000);
                } catch (error) {
                    this.$refs.snackbar.show(error.response.data.detail, 6000);
                    console.error("Error while saving the information", error);
                }
            }
        },
        /**
         * This function clears the form fields and close the beta invite form
         * Form includes name, phone_number, email and location
         * @clearFields 
         */
        ClearFields(){
            this.form.name = ''
            this.form.phone_number = ''
            this.form.email = ''
            this.form.location = ''
            this.errors.name = null,
            this.errors.email = null,
            this.errors.phone_number = null,
            this.errors.location = null
            this.closeBetaInviteForm()
        },  
        /** This function validates the length of name field
         * @validateName
         */
        validateName() {
            if (this.form.name.length > 100) {
                this.errors.name = 'Name cannot exceed 100 characters';
            } else {
                this.errors.name = null;
            }
        },
        /** This function validates the email
         * @validateName
         */
        validateEmail() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this.form.email)) {
                this.errors.email = 'Please enter a valid email location';
            } else {
                this.errors.email = null;
            }
        },
        /** This function validates the phone number field.
         * Whether it contains text or digits and length of characters
         * @validateName
         */
        validatePhone() {
            // Check for any non-digit characters
                const nonNumericCharacters = /[^\d]/g;

            if (nonNumericCharacters.test(this.form.phone_number)) {
                this.errors.phone_number = 'Phone number can only contain digits and cannot exceed 15 digits ';
            } else if (this.form.phone_number.length > 15) {
                // Validate if the phone number is exactly 15 digits
                this.errors.phone_number = 'Phone number cannot exceed 15 digits';
            } else {
                this.errors.phone_number = null;
            }
        },
        /** This function validates the length of location field
         * It restrict the user to not to enter more than 100 characters
         * @validateName
         */
        validateLocation() {
            if (this.form.location.length > 100) {
                this.errors.location = 'location cannot exceed 100 characters';
            } else {
                this.errors.location = null;
            }
        },
    }
}
</script>

<style scoped>

.form-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.form-content {
    background-color: #f8ffff;
    padding: 20px;
    width: auto;
    color: #000; /* Adjust text color here */
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.error {
    background-color: transparent;
    color: red;
    font-size: 0.9rem;
}

.is-invalid {
    border-color: red;
}

.v-application .error {
    background-color: transparent !important;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

</style>

