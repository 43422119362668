<template>
    <div class="my-playlists"  >
        <div style="display: flex;flex-direction: row;justify-content: space-between;width: 100%;">
            <c-H4-22 :color="colorTheme['Secondary-Gray0700']">Private space playlists</c-H4-22>
            <c-btn-secondary  as="a" :href="viewMorePlaylistsFn()" id="inputViewAllPlaylists">View more</c-btn-secondary>
        </div>
        <div v-if="loadingPlaylists" style="display: contents;">
            <c-frame-55 >
                <c-loader-div></c-loader-div>
            </c-frame-55>
        </div>
        <c-upcoming-courses v-if="(playlistsData.length>0)" class="my-playlist-list" >
            <c-abhyas-course-card style="width: 330px;"  v-for="(playlistDetails, index) in playlistsData" :key="index" position="relative">
                <c-description-div style="-webkit-line-clamp : 2; height : auto;" overflow="hidden" text_align="left" text_transform="none">
                    <!-- eslint-disable -->
                    <c-H4-22 :color="colorTheme['Secondary-Gray0700']">{{ playlistDetails.playlist_name }}</c-H4-22>
                </c-description-div>
                <c-row text_align="left" text_transform="none">
                    <c-overline-11>{{playlistDetails.author_details.name}}</c-overline-11>
                </c-row>
                <c-tag-cloud>
                    <c-tag-row>
                        <c-tag-pill v-if="!((playlistDetails.curriculum === null) || (playlistDetails.curriculum === 'null') || (playlistDetails.curriculum === ''))"><c-body-14>{{playlistDetails.curriculum}}</c-body-14></c-tag-pill>
                        <c-tag-pill v-if="!((playlistDetails.subject === null) || (playlistDetails.subject === 'null') || (playlistDetails.subject === ''))"><c-body-14>{{playlistDetails.subject}}</c-body-14></c-tag-pill>
                        <c-tag-pill v-if="!((playlistDetails.grade === null) || (playlistDetails.grade === 'null') || (playlistDetails.grade === ''))"><c-body-14>{{playlistDetails.grade}}</c-body-14></c-tag-pill>
                    </c-tag-row>
                </c-tag-cloud>
                <c-frame-16 style="width: auto;">
                    <c-btn-secondary as="a" :id="'inputViewplaylistDetails_' + (index + 1) " :href="UI_base_url + '/view-playlist/' + playlistDetails.id">View details</c-btn-secondary>
                </c-frame-16>
            </c-abhyas-course-card>
            <c-frame-55 v-if=" playlistsData.length === 0" >
                <c-row text_align="left" align_items="flex-start" text_transform="none">
                    <c-body-ux-14>You do not have any playlists.</c-body-ux-14>
                </c-row>
            </c-frame-55>
        </c-upcoming-courses>
    </div>
</template>
    
<script>

// import axios from 'axios';
import AppConfig from '../../config.js';
import { H4_22,OVERLINE_11, body_UX_14} from '../atoms/text_styles';
import { theme } from '../assets/theme';
import { descriptionDiv, frame_16, abhyasCourseCard, tagCloudDiv,tagRowDiv, rowDiv,loaderDiv,frame_55, upcomingCourses} from '../atoms/div_styles'
import buttonSecondary from '../molecules/button_secondary.vue'
import tag_pill from '../molecules/tag_pill.vue'
  
    export default {
        name: 'MyPlaylists',
        data() {
            return {
                colorTheme:theme,
                currentView: 'MyPlaylists', // Default view
                UI_base_url: AppConfig.UI_Base_URL,
                auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
                playlistsData:[],
                loadingPlaylists: ''
            };
        },
        props:{
            playlistsListdata:{
                type: Array
            },
            isLoadingPlaylists:{
                type: Boolean
            }
        },
        components:{
            // 'c-body-14': body_14,
            'c-description-div': descriptionDiv,
            'c-frame-16':frame_16,
            'c-row': rowDiv,
            // 'c-span-body-14': span_body_14,
            'c-overline-11': OVERLINE_11,
            'c-H4-22': H4_22,
            'c-abhyas-course-card': abhyasCourseCard,
            'c-tag-pill': tag_pill,
            'c-tag-cloud': tagCloudDiv,
            'c-tag-row': tagRowDiv,
            'c-btn-secondary': buttonSecondary,
            'c-upcoming-courses':upcomingCourses,
            'c-loader-div': loaderDiv,
            'c-frame-55': frame_55,
            'c-body-ux-14': body_UX_14
        },
        async mounted(){
            console.log("playlistsListdata", this.playlistsListdata);
            this.playlistsData = this.playlistsListdata
            this.loadingPlaylists = this.isLoadingPlaylists
        },
        methods: {
            viewMorePlaylistsFn(){
                let viewMorePlaylistsPageURL = this.UI_base_url + '/search-playlist' 
                return viewMorePlaylistsPageURL
            }
        }
    };
      
</script>
    
<style>
    .my-playlists {
      /* Add your styles here */
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 4px;
      gap: 8px;
      align-items: flex-start;
      border-radius: 8px;
      background: #F0FFFF;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    }
    .my-playlist-list{
      display: flex;
      flex-wrap: wrap;
    }
</style>
    