var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-home-page',{staticStyle:{"gap":"0px !important"}},[_c('c-top-nav-bar',{staticStyle:{"position":"relative !important"}}),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"heroSection"},[_c('vueper-slides',{ref:"myVueperSlides",attrs:{"fixed-height":"400px","slide-image-inside":"","arrows-outside":"","always-refresh-clones":"","autoplay":"","arrows":false,"pause-on-hover":_vm.pauseOnHover},on:{"autoplay-pause":function($event){_vm.internalAutoPlaying = false},"autoplay-resume":function($event){_vm.internalAutoPlaying = true}}},_vm._l((_vm.slides),function(slide,idx){return _c('vueper-slide',{key:idx,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"vueperslide__content-wrapper slideCardRenderer",style:({
                'background-image':
                  'linear-gradient(' +
                  _vm.blurDirection +
                  ', transparent ' +
                  (_vm.blurDirection === 'to bottom' ? '90%' : '55%') +
                  ', ' +
                  slide.color +
                  ' 100%, transparent), url(' +
                  slide.image +
                  ')',
                'background-color': slide.color,
              })},[_c('div',{staticClass:"vueperslide__title",domProps:{"innerHTML":_vm._s(slide.content)}})])]},proxy:true}],null,true)})}),1)],1),_c('c-about-us'),_c('c-tutor-invite'),_c('c-footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }